/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

input, select, textarea {
	vertical-align: middle;
}

html,body {
	-ms-overflow-style: scrollbar;
	height: 100%;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
border: 0;
font-size: 12px;
font: inherit;
vertical-align: baseline;
}
body {
  color: #252525;
  cursor: default;
}
body,
button,
input,
select,
textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}
/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
	background: #fff;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	font-size: 14px;
	color: #575757;
	font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

a {
	color: #575757;
	text-decoration: none;
}

.clear {
	clear: both;
}

.clearfix {
	*zoom: 1;
}

.clearfix:after {
	content: "\20";
	clear: both;
	display: block;
}

input {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	resize: none;
	border-radius: 0;
}

/* 去掉input number类型加减按钮 */
input[type='number']{
	-moz-appearance:textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* pc浏览器浏览移动项目样式处理 */
.pc_style{
	width: 640px !important;
	margin: 0 auto;
	/* left: 50% !important;
	margin-left: -260px !important; */
}
.pc_width {
	width: 640px !important;
}

/* 解决弹层滚动穿透 */
body.dialog-open {
	position: fixed;
	width: 100%;
}

/* 底部菜单 */

.public_pad {
	padding-bottom: 1rem;
}


/* 头部间距1 */

.between {
	margin-top: 1.66rem;
}


/* 头部间距2 */

.between-space {
	margin-top: 0.88rem;
}


/* tab */

.tabs-tab {
	position: relative
}

.tabs-tab:after {
	content: '';
	display: block;
	height: 2px;
	background: #3089ed;
	margin: 0 auto;
	width: 0;
	transition: all .5s
}

.tabs-tab.active:after {
	width: 60%
}


/* .active {
    color: #3089ed !important;
	} */


/* 进度条 */

.progress {
	height: 0.1rem;
	border-radius: 0.4rem;
	background: #eee;
	position: relative;
}

.progress-color {
	height: 100%;
	border-radius: 0.4rem;
	background: #ff8523;
	position: absolute;
	left: 0;
	z-index: 10;
}


/* 遮罩 */

.common_dialog {
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 200;
}
.common_none_dialog {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 66;
}

/* 阿里symbol图标通用样式 */
.icon {
	width: 1em; 
	height: 1em; 
	vertical-align: -0.15em; 
	fill: currentColor; 
	overflow: hidden;
}


.noSelected{
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ *//* No support for these yet, use at own risk */
    -o-user-select: none;
    user-select: none; 
}

input{
	border-radius: 5px !important;
}

.disFlexB{
	display: flex;
	height: 100%;
	justify-content: space-between;
	align-items: center;
}

.disFlexA{
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.ant-btn,.ant-picker,.ant-select-selector{
	border-radius: 5px !important;
}


.ant-table-tbody td{
	text-align: center;
}
.ant-table-cell{
	padding: 12px 16px !important;
}
.ant-btn-primary{
	background: #2e88ed;
	border-color: #2e88ed;
}

.jh_mc{
	color: #2e88ed;
}
.ant_table_bg_simple{
	background: #F8FBFF;
}

/* 修改ant-design 表格边框颜色 */
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td{
	border-right: 1px solid #E0E0E0 !important;
}
.ant-table-tbody > tr > td{
	border-bottom: 1px solid #E0E0E0 !important;
}
.ant-table.ant-table-bordered > .ant-table-container{
	border-left:1px solid #E0E0E0 !important;
}
.ant-table-content table{
	border-top: 1px solid #E0E0E0 !important;
}

.chjh_bgf{
	background: #ffffff;
}

.ant-table-thead >tr >th{
	text-align: center!important;
	border-right: 0px !important;
	font-size:14px;
	font-weight: 400 !important;
}

div .chjh_btn_type2{
	border: 1px solid #2e88ed;
	color: #2e88ed;
	background: #ffffff;
}

.cursor_p{
	cursor: pointer;
	-webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ *//* No support for these yet, use at own risk */
    -o-user-select: none;
    user-select: none; 
}

.wrap1200{
	width: 1200px!important;
	margin: 0 auto;
}
#root{
	background: #F5F5F5;
}
.blue{
	color:#2E88ED;
}
.orange{
	color:#FF8523;
}
.cursor{
	cursor: pointer;
}
.bg_white{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #fff;
	z-index: -1;
}

.ant-modal-confirm .ant-modal-confirm-btns{
	display: flex;
	justify-content: center;
	float: none !important;
	margin-top: 22px !important;
	/* text-align: center; */
}
.success_btn .ant-modal-content{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 371px;
	height: 259px;
	border-radius: 8px;
}
/* 修改input默认的样式 */
.ant-form-item-label,.ant-input{
	height: 42px;
	line-height: 42px;
}
